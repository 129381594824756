import React, { useMemo } from 'react'
import { LINK_TYPES, SHORT_URL_SELECTION_TYPE } from 'constants/general.constants'
import ColorfulTag from 'components/ColorfulTag/ColorfulTag'
import Tooltip from 'components/Tooltip/Tooltip'
import { isMobile } from 'react-device-detect'

/**
 * Represents a tag that is labled on a short to describe which type of url is this
 * Uses {@link ColorfulTag} to display the styled tag.
 * @param {string} type - The type of the short url
 * @param {boolean} isSmall - Determins whether this tag has reduced padding or not
 */
export default function LinkUrlTypeTag({
    type,
    isSmall = false,
    hasHorizontalMargin = false,
    showTooltip = false,
    tooltipText = ''
}) {

    const urlTypeData = useMemo(() => getUrlTypeTagData(type), [type])

    function getUrlTypeTagData(type) {
        switch (type) {
            case SHORT_URL_SELECTION_TYPE.PRODUCT: return { backgroundColor: '#d0e5fd', text: 'Short' }
            case SHORT_URL_SELECTION_TYPE.COLLECTION: return { backgroundColor: '#d0e5fd', text: 'Short' }
            case SHORT_URL_SELECTION_TYPE.ORGANIC: return { backgroundColor: '#f3d1bb', text: 'Promocode' }
            case LINK_TYPES.SHOPBOARD: return { backgroundColor: '#d9f2d5', text: 'Shop the Feed' }
            case LINK_TYPES.LOOK: return { backgroundColor: '#f2f1d5', text: 'Instant Store' }
            default: return { backgroundColor: '#d0e5fd', text: 'Short' }
        }
    }
    if (showTooltip && !isMobile) {
        return (
            <Tooltip
                image={<ColorfulTag
                    text={urlTypeData.text}
                    backgroundColor={urlTypeData.backgroundColor}
                    isSmall={isSmall}
                    hasHorizontalMargin={hasHorizontalMargin}
                />}
                tooltipText={tooltipText}
                oneLine={true}
        />
    )}
    return (
        <ColorfulTag
            text={urlTypeData.text}
            backgroundColor={urlTypeData.backgroundColor}
            isSmall={isSmall}
            hasHorizontalMargin={hasHorizontalMargin}
        />
    )
}