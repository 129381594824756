import React from 'react'
import './DashboardDataMultiValue.css'
import DashboardDataContainer from 'components/DashboardDataContainer/DashboardDataContainer'
import BusinessAnalyticsDataLoader from 'components/BusinessAnalyticsDataLoader/BusinessAnalyticsDataLoader'

/**
 * Represents a multiple-value dashboard data view. Uses {@link DashboardDataContainer}.
 * @param {string/number} width - The width of this container
 * @param {string/number} height - The height of this container
 * @param {string} title - The title of this view
 * @param {boolean} shouldSpreadItems - If true, the title and the content will be spread to the vertical edges.
 * @param {array} dataArray - The array of values to display
 */
export default function DashboardDataMultiValue({
    width,
    height,
    title = '',
    shouldSpreadItems = false,
    dataArray = [],
    isLoadingData = false,
}) {
    return (
        <DashboardDataContainer
            title={title}
            width={width}
            height={height}
            shouldSpreadItems={shouldSpreadItems}
        >
            <div className="dashboard-data-multi-value-values-container">
                {
                    dataArray.map(data => <div key={data?.name} className='dashboard-data-multi-value-data-container'>
                        <div className="dashboard-data-multi-value-data-value">{isLoadingData ? <BusinessAnalyticsDataLoader width='20px' height='20px' /> : data?.value}</div>
                        <div className="dashboard-data-multi-value-data-value-container">
                            <div className="dashboard-data-multi-value-data-value-bullet" style={{ backgroundColor: data?.bulletColor }}></div>
                            <div className="dashboard-data-multi-value-data-value-text">{data?.name}</div>
                        </div>
                    </div>)
                }
            </div>
        </DashboardDataContainer>
    )
}