import React from 'react'
import './ContentDialog.css'
import { isMobile } from 'react-device-detect'
import { CONTENT_TYPES, INFLUENCER_PREVIEW_DIALOG_MAX_WIDTH, LINK_TYPES, VIEW_MODES } from 'constants/general.constants'
import PaydinDialog from 'dialogs/PaydinDialog/PaydinDialog'
import LinkView from 'components/LinkView/LinkView'
import ShortView from 'components/ShortView/ShortView'
import InfluencerView from 'components/InfluencerView/InfluencerView'
import { AiOutlineClose } from 'react-icons/ai'
import { useHistoryContext } from 'contexts/History'
import PreviewLinkSection from 'components/PreviewLinkSection/PreviewLinkSection'
import InfluencerPreviewPage from 'pages/InfluencerPreviewPage/InfluencerPreviewPage'
import PreviewMultilinkSection from 'components/PreviewMultilinkSection/PreviewMultilinkSection'
import MultilinkView from 'components/MultilinkView/MultilinkView'

export default function ContentDialog({
    mode = VIEW_MODES.CREATE,
    isDialogOpen = false,
    handleDialogClose = () => { },
    data = {},
    contentType = CONTENT_TYPES.LINK,
    onSaveSuccess = () => { },
    onDelete = () => { },
    onCreateSuccess = () => { }
}) {    
    const history = useHistoryContext()

    function renderDialogContent(contentType) {
        switch (contentType) {
            case CONTENT_TYPES.LINK:
                if (mode === VIEW_MODES.PREVIEW) {
                    return <PreviewLinkSection
                        type={LINK_TYPES.LOOK}
                        data={data}
                        onSaveSuccess={onSaveSuccess}
                        onDeleteLink={onDelete}
                    />
                }
                return <LinkView
                    mode={mode}
                    data={data}
                    onSaveSuccess={onSaveSuccess}
                    onCreateSuccess={onCreateSuccess}
                />
            case CONTENT_TYPES.SHORT:
                if (mode === VIEW_MODES.PREVIEW) {
                    return <PreviewLinkSection
                        type={LINK_TYPES.SHORT}
                        data={data}
                        onSaveSuccess={onSaveSuccess}
                        onDeleteLink={onDelete}
                    />
                }
                return <ShortView
                    mode={mode}
                    data={data}
                    onSaveSuccess={onSaveSuccess}
                    onCreateSuccess={onCreateSuccess}
                />
            case CONTENT_TYPES.MULTILINK:
                if (mode === VIEW_MODES.PREVIEW) {
                    return <PreviewMultilinkSection
                        data={data}
                        onSaveSuccess={onSaveSuccess} 
                        onDeleteMultilink={onDelete}
                    />
                }
                return <MultilinkView
                    mode={mode}
                    data={data}
                    onSaveSuccess={onSaveSuccess}
                    onCreateSuccess={onCreateSuccess}
                />
            default: 
                if (mode === VIEW_MODES.PREVIEW) {
                    return <InfluencerPreviewPage
                        data={data}
                        onDeleteInfluencer={onDelete}
                        onInfluencerSaveSuccess={onSaveSuccess}
                    />
                }
                return <InfluencerView // influencer
                    mode={mode}
                    data={data}
                    onSaveSuccess={onSaveSuccess}
                    onDeleteInfluencer={onDelete}
                    onCreateSuccess={onCreateSuccess}
                />
        }
    }

    function getDialogWidth() {
        if (isMobile) {
            return '100vw'
        } else {
            switch (contentType) {
                case CONTENT_TYPES.LINK: return 1100
                case CONTENT_TYPES.SHORT: {
                    if (mode === VIEW_MODES.PREVIEW) {
                        return '55vw'
                    }

                    return 700
                }
                case CONTENT_TYPES.MULTILINK: {
                    if (mode === VIEW_MODES.PREVIEW) {
                        return '65vw'
                    }

                    return 700
                }
                default: { // INFLUENCER
                    if (mode !== VIEW_MODES.PREVIEW) {
                        return '50vw'
                    }

                    return `min(${INFLUENCER_PREVIEW_DIALOG_MAX_WIDTH}px, 60vw)` // Avoid more the 1000 px width
                }
            }
        }
    }

    function getDialogHeight() {
        if (isMobile) {
            return '100dvh'
        } else {
            return 'var(--content-dialog-height)'
        }
    }

    return (
        <>
            {(!isMobile && isDialogOpen) && <AiOutlineClose className="content-dialog-close-button" onClick={() => history.goBack()} />}
            <PaydinDialog
                isDialogOpen={isDialogOpen}
                handleDialogClose={handleDialogClose}
                hasNoWidthLimit={true}
                backdropColor='rgba(0, 0, 0, 0.65)'
                borderRadiusPx={isMobile ? 0 : 12}
                paddingPx={0}
                width={getDialogWidth()}
                height={getDialogHeight()}
                minHeight={getDialogHeight()}
                margin='0px'
                dialogLayout={renderDialogContent(contentType)}
                isCloseOnTouchOutside={true}
                styles={{
                    overflow: 'hidden'
                }}
            />
        </>
    )
}