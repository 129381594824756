import React, { useEffect, useRef, useState } from 'react'
import './InfluencerPreviewPage.css'
import TimeSelect from 'components/TimeSelect/TimeSelect'
import { useTranslation } from 'react-i18next'
import {
    INFLUENCER_LINK_PAGINATION_COUNT,
    LINK_TYPES,
    MOBILE_DASHBOARD_TOPBAR_OPTIONS_DRAWER_CLOSING_DELAY,
    PAYDIN_MENU_OPENING_DIRECTIONS,
    SHOPEAKS_SMALL_LOGO_URL,
    ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS,
    ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES,
    INFLUENCER_SHORT_PAGINATION_COUNT,
    INFLUENCER_TAB_OPTIONS,
    CONTENT_TYPES,
    VIEW_MODES,
    PAYDIN_DRAWER_TRANSITION_DURATION,
    NUMBER_TYPES,
    INFLUENCER_PREVIEW_LINKS_PAGINATION_OFFSET,
    INFLUENCER_PREVIEW_SHORTS_PAGINATION_OFFSET
} from 'constants/general.constants'
import ImageBox from 'components/ImageBox/ImageBox'
import { BiDotsHorizontalRounded, BiDotsVerticalRounded, BiLink } from 'react-icons/bi'
import { AiOutlineClose } from 'react-icons/ai'
import { TbFileDownload } from "react-icons/tb";
import {
    MdEdit,
    MdDeleteOutline,
    MdOutlineLockReset,
    MdOutlineContentCopy
} from 'react-icons/md'
import { formatNumber } from 'utils/utils'
import NoItemsFound from 'components/NoItemsFound/NoItemsFound'
import LinkItem from 'components/LinkItem/LinkItem'
import { GetInfluencerLinks } from 'api/links/links'
import { useGeneralDataContext, useUserDetailsContext } from 'contexts/User'
import {
    GetInfluencer,
    GetInfluencerAnalytics,
    GetInfluencerAnalyticsReport,
    deactivateInfluencer,
    resetInfluencerPassword
} from 'api/influencers/influencers'
import PaydinMenu from 'components/PaydinMenu/PaydinMenu'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import { BrowserView, isMobile } from 'react-device-detect'
import { useHistoryContext } from 'contexts/History'
import {
    LOGIN_INFLUENCER_INFLUENCER_PASSWORD_QUERY_PARAMETER,
    LOGIN_INFLUENCER_INFLUENCER_USERNAME_QUERY_PARAMETER,
    LOGIN_INFLUENCER_MERCHANT_NAME_QUERY_PARAMETER,
    LOGIN_INFLUENCER_PAGE_URL,
} from 'constants/routes.constants'
import PaydinDrawerListContent from 'components/PaydinDrawerListContent/PaydinDrawerListContent'
import Tabs from 'components/Tabs/Tabs'
import ShortItem from 'components/ShortItem/ShortItem'
import { getAnalyticsDateString, getTimezone } from 'utils/dateUtils'
import InfluencerAnalyticsLoader from 'components/InfluencerAnalyticsLoader/InfluencerAnalyticsLoader'
import { getCurrencySymbol } from 'services/currency'
import { copyToClipboard } from 'services/SystemService'
import ShortsImage from 'embeddedImages/ShortsImage'
import {
    extractLinkData,
    extractExtendedInfluencerAnalytics,
    extractInfluencerData
} from 'utils/dataUtils'
import InfluencerLinksShortsLoader from 'components/InfluencerLinksShortsLoader/InfluencerLinksShortsLoader'
import InfluencerInsights from 'components/InfluencerInsights/InfluencerInsights'
import ContentDialog from 'dialogs/ContentDialog/ContentDialog'
import ContentDialogTopbar from 'components/ContentDialogTopbar/ContentDialogTopbar'
import useOnScreen from 'hooks/useOnScreen'
import Loader from 'components/Loader/Loader'
import AddLInkFlow from 'components/AddLInkFlow/AddLInkFlow'

/**
 * Represents a preview page for a specific influencer's data.
 */
export default function InfluencerPreviewPage({
    data,
    onDeleteInfluencer = () => { },
    onInfluencerSaveSuccess = () => { }
}) {
    const { t } = useTranslation()
    const {
        userDetails,
        isFreeUserAndTrialEnded
    } = useUserDetailsContext()
    const {
        showApprovalMessage,
        openDrawer,
        closeDrawer,
        finishedUpdatingObject,
        finishedCreatingObject,
        objectToSave,
        openFreeTrialDialog,
    } = useGeneralDataContext()
    const history = useHistoryContext()

    const [selectedTabOptionIndex, setSelectedTabOptionIndex] = useState(0)

    const [overviewTimeIntervalName, setOverviewTimeIntervalName] = useState(ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.THIS_MONTH)
    const [datePickerState, setDatePickerState] = useState({
        startDate: ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS.find(interval => interval?.name === ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.THIS_MONTH).timeRange[0],
        endDate: ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS.find(interval => interval?.name === ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.THIS_MONTH).timeRange[1]
    })
    const [shouldApplyDateRange, setShouldApplyDateRange] = useState(true)
    const [influencerData, setInfluencerData] = useState({
        username: '',
        image: '',
        email: '',
        utm: '',
        promocode: '',
        commission: null,
        password: '',
        analytics: {
            clicks: 0,
            sales: 0,
            revenue: 0
        },
        links: [],
        shorts: []
    })
    const [isLoadingInfluencerLinks, setIsLoadingInfluencerLinks] = useState(true)
    const [isLoadingInfluencerLinksAnalytics, setIsLoadingInfluencerAnalytics] = useState(true)
    const [isLoadingInfluencerReport, setIsLoadingInfluencerReport] = useState(false)
    const [optionsMenuState, setOptionsMenuState] = useState({
        isOpen: false,
        anchorElement: null,
        onClose: closeOptionsMenu
    })
    const [dialogState, setDialogState] = useState({
        isOpen: false,
        title: '',
        message: '',
        leftButtonText: '',
        rightButtonText: '',
        rightButtonClickHandler: () => { }
    })
    const [shouldPopulateLinksContent, setShouldPopulateLinksContent] = useState(false)
    const [hasMoreItemsState, setHasMoreItemsState] = useState({
        links: true,
        shorts: true
    })
    const [loadingMoreItems, setLoadingMoreItems] = useState(false)
    const [contentDialogState, setContentDialogState] = useState({
        isOpen: false,
        mode: VIEW_MODES.CREATE,
        onSaveSuccess: () => { }
    })

    const [addFlowTrigger, setAddFlowTrigger] = useState(false)

    const linksListRef = useRef(null)
    const tabsRef = useRef(null)
    const analyticsStripRef = useRef(null)
    const paginationTriggeringLinkRef = useRef(null)

    const isTargetLinkInView = useOnScreen(paginationTriggeringLinkRef)

    useEffect(() => {
        loadInfluencer()
    }, [])

    useEffect(() => {
        if (isTargetLinkInView) {
            handlePageScroll()
        }
    }, [isTargetLinkInView])

    useEffect(() => {
        loadInfluencerAnalytics()
    }, [shouldApplyDateRange])

    useEffect(() => {
        loadInfluencerDetails()
    }, [selectedTabOptionIndex])

    useEffect(() => {
        if (finishedUpdatingObject) {
            loadInfluencer()
        }
    }, [finishedUpdatingObject])

    function loadInfluencer() {
        GetInfluencer(data?.influencerUsername)
            .then(response => {
                setInfluencerData(prev => ({
                    ...prev,
                    ...extractInfluencerData(response)
                }))
            })
            .catch(error => {
                console.log(error)
            })
    }

    function loadInfluencerDetails(reset=false) {
        if (isLinksTabSelected() && (influencerData?.links?.length === 0 || reset)) {
            setShouldPopulateLinksContent(false)
            setIsLoadingInfluencerLinks(true)
            GetInfluencerLinks(userDetails?.businessId, data?.influencerUsername, INFLUENCER_LINK_PAGINATION_COUNT, 0, LINK_TYPES.LOOK)
                .then(response => {
                    setInfluencerData(prev => ({
                        ...prev,
                        links: response?.links?.map(link => extractLinkData(link))
                    }))

                    setHasMoreItemsState(prev => ({
                        ...prev,
                        links: response?.links?.length === INFLUENCER_LINK_PAGINATION_COUNT
                    }))
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    setIsLoadingInfluencerLinks(false)
                    setShouldPopulateLinksContent(true)
                })
        } else if (isShortsTabSelected() && (influencerData?.shorts?.length === 0  || reset)) {
            setShouldPopulateLinksContent(false)
            setIsLoadingInfluencerLinks(true)
            GetInfluencerLinks(userDetails?.businessId, data?.influencerUsername, INFLUENCER_SHORT_PAGINATION_COUNT, 0, LINK_TYPES.SHORT)
                .then(response => {
                    setInfluencerData(prev => ({
                        ...prev,
                        shorts: response?.links?.map(link => extractLinkData(link))
                    }))

                    setHasMoreItemsState(prev => ({
                        ...prev,
                        shorts: response?.links?.length === INFLUENCER_SHORT_PAGINATION_COUNT
                    }))
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    setIsLoadingInfluencerLinks(false)
                    setShouldPopulateLinksContent(true)
                })
        }
    }

    function loadInfluencerAnalytics() {
        if (shouldApplyDateRange) {
            setIsLoadingInfluencerAnalytics(true)
            GetInfluencerAnalytics(data?.influencerUsername, getAnalyticsDateString(datePickerState.startDate), getAnalyticsDateString(datePickerState.endDate))
                .then(influencer => {
                    setInfluencerData(prev => ({
                        ...prev,
                        ...extractExtendedInfluencerAnalytics(influencer)
                    }))
                    setIsLoadingInfluencerAnalytics(false)
                })
                .catch(error => {
                    console.log(error)
                    setIsLoadingInfluencerAnalytics(false)
                })
            setShouldApplyDateRange(false)
        }
    }

    function downloadInfluencerReport() {
        if (isLoadingInfluencerReport) {
            return
        }

        if (isMobile) {
            closeDrawer()
        }

        setIsLoadingInfluencerReport(true)
        const from_date = getAnalyticsDateString(datePickerState.startDate)
        const to_date = getAnalyticsDateString(datePickerState.endDate)
        const report_name = `${data?.influencerUsername}_report${from_date ? `_${from_date}` : ''}${to_date ? `_to_${to_date}` : ''}.csv`
        const localTimezone = getTimezone()
        GetInfluencerAnalyticsReport(data?.influencerUsername, from_date, to_date, localTimezone? localTimezone : "UTC", report_name)
            .then(() => {
                setIsLoadingInfluencerReport(false)
            })
            .catch(error => {
                console.log(error)
                setIsLoadingInfluencerReport(false)
            })
    }

    function onDateRangeSelected(startDate, endDate) {
        setDatePickerState({
            startDate,
            endDate
        })
    }

    function hasNoInfluencerLinks() {
        return influencerData?.links && influencerData?.links.length === 0
    }

    function hasNoInfluencerShorts() {
        return influencerData?.shorts && influencerData?.shorts.length === 0
    }

    function closeDialog() {
        setDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function openOptionsMenu(e) {
        setOptionsMenuState({
            isOpen: true,
            anchorElement: e.currentTarget,
            onClose: () => setOptionsMenuState(prev => ({
                ...prev,
                isOpen: false
            }))
        })
    }

    function closeOptionsMenu() {
        setOptionsMenuState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function showResetInfluencerPasswordConfirmationDialog() {
        if (isMobile)
            closeDrawer()
        else
            optionsMenuState.onClose()

        setTimeout(() => {
            setDialogState({
                isOpen: true,
                handleDialogClose: closeDialog,
                title: t('PREVIEW_INFLUENCER_RESET_INFLUENCER_PASSWORD_CONFIRMATION_DIALOG_TITLE'),
                message: t('PREVIEW_INFLUENCER_RESET_INFLUENCER_PASSWORD_CONFIRMATION_DIALOG_MESSAGE', { name: data?.influencerUsername }),
                leftButtonText: t('PREVIEW_INFLUENCER_RESET_INFLUENCER_PASSWORD_CONFIRMATION_DIALOG_LEFT_BUTTON_TEXT'),
                rightButtonText: t('PREVIEW_INFLUENCER_RESET_INFLUENCER_PASSWORD_CONFIRMATION_DIALOG_RIGHT_BUTTON_TEXT'),
                rightButtonClickHandler: influencerResetPassword
            })
        }, MOBILE_DASHBOARD_TOPBAR_OPTIONS_DRAWER_CLOSING_DELAY)
    }

    function influencerResetPassword() {
        resetInfluencerPassword(data?.influencerUsername)
            .then((response) => {
                history.goBack()
                setInfluencerData(prev => ({
                    ...prev,
                    ...extractInfluencerData(response)
                }))
                showApprovalMessage(t('PREVIEW_INFLUENCER_RESET_INFLUENCER_PASSWORD_SUCCESS_APPROVAL_MESSAGE', { name: data?.influencerUsername }))
            })
            .catch(error => {
                console.log(error)
                setDialogState({
                    isOpen: true,
                    handleDialogClose: closeDialog,
                    title: t('PREVIEW_INFLUENCER_RESET_INFLUENCER_PASSWORD_FAIL_DIALOG_TITLE'),
                    leftButtonText: t('PREVIEW_INFLUENCER_RESET_INFLUENCER_PASSWORD_FAIL_DIALOG_LEFT_BUTTON_TEXT'),
                    leftButtonClickHandler: () => { }
                })
            })
    }

    function openOptionsDrawer() {
        openDrawer(false, <PaydinDrawerListContent
                options={[
                    {
                        text: t('PREVIEW_INFLUENCER_MORE_OPTIONS_MENU_EDIT_OPTION_TEXT'),
                        image: <MdEdit className='mobile-influencer-preview-more-options-drawer-option-image' />,
                        onClick: editInfluencer
                    },
                    {
                        text: t('HOME_PAGE_CREATE_LINK_BUTTON_TEXT'),
                        image: <BiLink className="mobile-dashboard-topbar-add-options-menu-option-image" />,
                        onClick: createNewLink
                    },
                    {
                        text: t('HOME_PAGE_CREATE_SHORT_BUTTON_TEXT'),
                        image: <ShortsImage className="mobile-dashboard-topbar-add-options-menu-option-image" />,
                        onClick: createNewShort
                    },
                    {
                        text: t('PREVIEW_INFLUENCER_MORE_OPTIONS_MENU_COPY_INFLUENCER_LOGIN_LINK_OPTION_TEXT'),
                        image: <MdOutlineContentCopy className='mobile-influencer-preview-more-options-drawer-option-image' />,
                        onClick: copyInfluencerLoginInformation
                    },
                    {
                        text: t('PREVIEW_INFLUENCER_MORE_OPTIONS_MENU_DOWNLOAD_INFLUENCER_REPORT_OPTION_TEXT'),
                        image: <TbFileDownload className='mobile-influencer-preview-more-options-drawer-option-image' />,
                        onClick: downloadInfluencerReport
                    },
                    {
                        text: t('PREVIEW_INFLUENCER_MORE_OPTIONS_MENU_RESET_INFLUENCER_PASSWORD_OPTION_TEXT'),
                        image: <MdOutlineLockReset className='mobile-influencer-preview-more-options-drawer-option-image' />,
                        onClick: showResetInfluencerPasswordConfirmationDialog
                    },
                    {
                        text: t('PREVIEW_INFLUENCER_MORE_OPTIONS_MENU_DELETE_OPTION_TEXT'),
                        className: 'mobile-influencer-preview-more-options-drawer-delete-option-image',
                        image: <MdDeleteOutline className='mobile-influencer-preview-more-options-drawer-option-image' />,
                        onClick: showDeleteInfluencerConfirmationDialog
                    },
                    {
                        text: t('PREVIEW_INFLUENCER_MORE_OPTIONS_MENU_CANCEL_OPTION_TEXT'),
                        image: <AiOutlineClose className='mobile-influencer-preview-more-options-drawer-option-image' />,
                        onClick: closeDrawer
                    }
                ]}
            />)
    }

    function editInfluencer() {
        if (isMobile)
            closeDrawer()
        else
            optionsMenuState.onClose()

        setTimeout(() => {
            if (isFreeUserAndTrialEnded()) {
                openFreeTrialDialog()
            } else {
                setContentDialogState({
                    isOpen: true,
                    mode: VIEW_MODES.EDIT,
                    contentType: CONTENT_TYPES.INFLUENCER,
                    data: {
                        influencerUsername: data?.influencerUsername
                    },
                    onSaveSuccess: updatedInfluencer => {
                        loadInfluencer()
                        onInfluencerSaveSuccess(updatedInfluencer)
                    }
                })
            }
        }, PAYDIN_DRAWER_TRANSITION_DURATION)
    }

    async function copyInfluencerLoginInformation() {
        if (isMobile)
            closeDrawer()
        else
            optionsMenuState.onClose()

        const link = `${window.location.host}${LOGIN_INFLUENCER_PAGE_URL}?${LOGIN_INFLUENCER_MERCHANT_NAME_QUERY_PARAMETER}=${userDetails?.username}&${LOGIN_INFLUENCER_INFLUENCER_USERNAME_QUERY_PARAMETER}=${influencerData?.username}&${LOGIN_INFLUENCER_INFLUENCER_PASSWORD_QUERY_PARAMETER}=${influencerData?.password}`
        await copyToClipboard(link)
        showApprovalMessage(t('PREVIEW_INFLUENCER_INFLUENCER_LOGIN_LINK_COPIED_TO_CLIPBOARD'))
    }

    function showDeleteInfluencerConfirmationDialog() {
        if (isMobile)
            closeDrawer()
        else
            optionsMenuState.onClose()

        setTimeout(() => {
            setDialogState({
                isOpen: true,
                handleDialogClose: closeDialog,
                title: t('PREVIEW_INFLUENCER_DELETE_INFLUENCER_CONFIRMATION_DIALOG_TITLE'),
                message: t('PREVIEW_INFLUENCER_DELETE_INFLUENCER_CONFIRMATION_DIALOG_MESSAGE', { name: data?.influencerUsername }),
                leftButtonText: t('PREVIEW_INFLUENCER_DELETE_INFLUENCER_CONFIRMATION_DIALOG_LEFT_BUTTON_TEXT'),
                rightButtonText: t('PREVIEW_INFLUENCER_DELETE_INFLUENCER_CONFIRMATION_DIALOG_RIGHT_BUTTON_TEXT'),
                rightButtonClickHandler: deleteInfluencer
            })
        }, PAYDIN_DRAWER_TRANSITION_DURATION)
    }

    function deleteInfluencer() {
        deactivateInfluencer(data?.influencerUsername)
            .then(_ => {
                onDeleteInfluencer(data?.influencerUsername)
                history.goBack(2)
            })
            .catch(error => {
                console.log(error)
                history.goBack()
            })
    }

    function renderTabs() {
        return <Tabs
            selectedTabOptionIndex={selectedTabOptionIndex}
            setSelectedTabOptionIndex={setSelectedTabOptionIndex}
            tabs={INFLUENCER_TAB_OPTIONS}
            inputRef={tabsRef}
        />
    }

    function isInsightsTabSelected() {
        return selectedTabOptionIndex === 0
    }

    function isLinksTabSelected() {
        return selectedTabOptionIndex === 1
    }

    function goToLinksTab() {
        return setSelectedTabOptionIndex(1)
    }

    function isShortsTabSelected() {
        return selectedTabOptionIndex === 2
    }

    function goToShortsTab() {
        return setSelectedTabOptionIndex(2)
    }

    function getNoLinksElementClassName() {
        if (isLinksTabSelected()) {
            return hasNoInfluencerLinks() ? 'no-influencer-links' : ''
        } else if (isShortsTabSelected()) {
            return hasNoInfluencerShorts() ? 'no-influencer-shorts' : ''
        } else {
            return ''
        }
    }

    function getItemsListClassName() {
        if (isLinksTabSelected()) {
            return `${isMobile ? 'mobile-' : ''}influencer-preview-links-list`
        } else if (isShortsTabSelected()) {
            return `${isMobile ? 'mobile-' : ''}influencer-preview-shorts-list`
        } else {
            return 'influencer-preview-influencer-insights'
        }
    }

    function renderDateRangeSelect() {
        return <TimeSelect
            selectedStartDate={datePickerState.startDate}
            selectedEndDate={datePickerState.endDate}
            timeIntervals={ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS}
            setStartEndDates={onDateRangeSelected}
            selectedTimeInterval={overviewTimeIntervalName}
            onTimeIntervalSelect={setOverviewTimeIntervalName}
            setShouldApplyDateRange={setShouldApplyDateRange}
        />
    }

    function renderInfluencerAnalytics() {
        return <div ref={analyticsStripRef} className={isMobile ? "mobile-influencer-preview-influencer-analytics" : "influencer-preview-influencer-analytics"} style={{ width: getInfluencerAnalyticsStripWidth() }}>
            <div className="influencer-preview-influencer-analytics-section">
                <div className="influencer-preview-influencer-analytics-section-value-container">
                    {
                        isLoadingInfluencerLinksAnalytics ? <InfluencerAnalyticsLoader /> : <div className="influencer-preview-influencer-analytics-section-value">{formatNumber(influencerData?.clicks, NUMBER_TYPES.SHORT)}</div>
                    }
                </div>
                <div className="influencer-preview-influencer-analytics-section-label">{t('PREVIEW_INFLUENCER_ANALYTICS_CLICKS_LABEL')}</div>
            </div>
            <div className="influencer-preview-influencer-analytics-section">
                <div className="influencer-preview-influencer-analytics-section-value-container">
                    {
                        isLoadingInfluencerLinksAnalytics ? <InfluencerAnalyticsLoader /> : <div className="influencer-preview-influencer-analytics-section-value">{formatNumber(influencerData?.totalSales, NUMBER_TYPES.SHORT)}</div>
                    }
                </div>
                <div className="influencer-preview-influencer-analytics-section-label">{t('PREVIEW_INFLUENCER_ANALYTICS_SALES_LABEL')}</div>
            </div>
            <div className="influencer-preview-influencer-analytics-section">
                <div className="influencer-preview-influencer-analytics-section-value-container">
                    {
                        isLoadingInfluencerLinksAnalytics ? <InfluencerAnalyticsLoader /> : <div className="influencer-preview-influencer-analytics-section-value">{getCurrencySymbol(influencerData?.currency)}{formatNumber(influencerData?.totalRevenue, NUMBER_TYPES.SHORT)}</div>
                    }
                </div>
                <div className="influencer-preview-influencer-analytics-section-label">{t('PREVIEW_INFLUENCER_ANALYTICS_REVENUE_LABEL')}</div>
            </div>
        </div>
    }

    function getInfluencerAnalyticsStripWidth() {
        let count = 3

        if (influencerData?.commission) {
            count = 4
        }

        if (count === 3)
            return '90%'
        return '80%'
    }

    function handlePageScroll() {
        if (isLinksTabSelected() || isShortsTabSelected()) {
            if (!isLoadingInfluencerLinks && (isLinksTabSelected() ? hasMoreItemsState.links : hasMoreItemsState.shorts)) {
                setLoadingMoreItems(true)
                GetInfluencerLinks(userDetails?.businessId, data?.influencerUsername, isLinksTabSelected() ? INFLUENCER_LINK_PAGINATION_COUNT : INFLUENCER_SHORT_PAGINATION_COUNT, isLinksTabSelected() ? influencerData?.links?.length : influencerData?.shorts?.length, isLinksTabSelected() ? LINK_TYPES.LOOK : LINK_TYPES.SHORT)
                    .then(linksData => {
                        setInfluencerData(prev => ({
                            ...prev,
                            ...(isLinksTabSelected() ? { links: [...prev.links, ...(linksData?.links?.map(link => extractLinkData(link)))] } : { shorts: [...prev.shorts, ...(linksData?.links?.map(link => extractLinkData(link)))] })
                        }))
                        setHasMoreItemsState(prev => ({
                            ...prev,
                            ...(isLinksTabSelected() ? { links: linksData?.links.length === INFLUENCER_LINK_PAGINATION_COUNT } : { shorts: linksData?.links.length === INFLUENCER_SHORT_PAGINATION_COUNT })
                        }))
                    })
                    .catch(error => {
                        console.log(error)
                    })
                    .finally(() => {
                        setLoadingMoreItems(false)
                    })
            }
        }
    }

    function createNewLink() {
        if (isMobile) {
            closeDrawer()
        }

        setTimeout(() => {
            if (isFreeUserAndTrialEnded()) {
                openFreeTrialDialog()
            } else {
                setAddFlowTrigger(true)
            }
        }, PAYDIN_DRAWER_TRANSITION_DURATION)
    }

    function createNewShort() {
        if (isMobile) {
            closeDrawer()
        }

        setTimeout(() => {
            if (isFreeUserAndTrialEnded()) {
                openFreeTrialDialog()
            } else {
                setContentDialogState({
                    isOpen: true,
                    mode: VIEW_MODES.CREATE,
                    contentType: CONTENT_TYPES.SHORT,
                    data: {
                        influencerUsername: data?.influencerUsername
                    },
                    onSaveSuccess: () => loadInfluencerDetails(true)
                })
            }
        }, PAYDIN_DRAWER_TRANSITION_DURATION)
    }

    useEffect(() => {
        // Check if of type influencer
        if (objectToSave && objectToSave?.influencerUsername && objectToSave?.influencerUsername === data?.influencerUsername) {
            if (finishedUpdatingObject || finishedCreatingObject) {
                if (objectToSave && objectToSave?.linkId && objectToSave?.linkType) {
                    if (objectToSave?.linkType == LINK_TYPES.LOOK) {
                        goToLinksTab()
                    } else if (objectToSave?.linkType == LINK_TYPES.SHORT) {
                        goToShortsTab()
                    }
                }
                loadInfluencerDetails(true)
            }
        }
    }, [finishedUpdatingObject, finishedCreatingObject])

    function renderTabContent() {
        if (isLinksTabSelected()) {
            if (shouldPopulateLinksContent) {
                if (hasNoInfluencerLinks()) {
                    return <NoItemsFound
                        image={SHOPEAKS_SMALL_LOGO_URL}
                        title={t('LINKS_PAGE_NO_LINKS_TITLE')}
                        buttonText={t('LINKS_PAGE_NO_LINKS_CREATE_LINK_BUTTON_TEXT')}
                        showButton={false}
                    />
                } else {
                    return influencerData?.links.map((link, index) => {
                        return <LinkItem
                            key={link?.linkId}
                            linkId={link?.linkId}
                            image={link?.image}
                            defaultImage={link?.defaultImage}
                            views={link?.views}
                            createdAt={link?.createdAt}
                            title={link?.title}
                            influencerUsername={link?.influencerUsername}
                            allowOrderDetails={false} //TODO - Fix bug in influencer preview link order details state not updating on click, for now removing the details options from and inner influencer link orders table.
                            {...(index === influencerData?.links.length - 1 - INFLUENCER_PREVIEW_LINKS_PAGINATION_OFFSET && (isLinksTabSelected() ? hasMoreItemsState.links : hasMoreItemsState.shorts) ? { elementRef: paginationTriggeringLinkRef } : {})}
                        />
                    })
                }
            }
        } else if (isShortsTabSelected()) {
            if (shouldPopulateLinksContent) {
                if (hasNoInfluencerShorts()) {
                    return <NoItemsFound
                        image={SHOPEAKS_SMALL_LOGO_URL}
                        title={t('SHORTS_PAGE_NO_SHORTS_TITLE')}
                        showButton={false}
                    />
                } else {
                    return influencerData?.shorts.map((short, index) => <ShortItem
                        key={short?.linkId}
                        shortId={short?.linkId}
                        image={short?.image}
                        defaultImage={short?.defaultImage}
                        views={short?.views}
                        checkouts={short?.checkouts}
                        revenue={short?.revenue}
                        createdAt={short?.createdAt}
                        currency={short?.currency}
                        isLinkExpired={short?.isLinkExpired}
                        title={short?.title}
                        url={short?.shoppingRedirectUrl}
                        urlType={short?.urlType}
                        influencerUsername={short?.influencerUsername}
                        allowOrderDetails={false} //TODO - Fix bug in influencer preview link order details state not updating on click, for now removing the details options from and inner influencer link orders table.
                        {...(index === influencerData?.shorts.length - 1 - INFLUENCER_PREVIEW_SHORTS_PAGINATION_OFFSET && (isLinksTabSelected() ? hasMoreItemsState.links : hasMoreItemsState.shorts) ? { elementRef: paginationTriggeringLinkRef } : {})}
                    />)
                }
            }
        } else {
            return <div className={isMobile ? 'mobile-infuencer-preview-page-insights-container' : 'infuencer-preview-page-insights-container'}>
                {!isMobile && renderDateRangeSelect()}
                <InfluencerInsights
                    totalRevenue={influencerData?.totalRevenue}
                    totalRefunded={influencerData?.totalRefunded}
                    commission={influencerData?.commission}
                    totalLinksSales={influencerData?.salesLinks}
                    totalShortsSales={influencerData?.salesShorts}
                    totalOrganicSales={influencerData?.salesOrganic}
                    totalLinksRevenue={influencerData?.revenueLinks}
                    totalShortsRevenue={influencerData?.revenueShorts}
                    totalOrganicRevenue={influencerData?.revenueOrganic}
                    linksClicks={influencerData?.linksClicks}
                    shortsClicks={influencerData?.shortsClicks}
                    currency={influencerData?.currency}
                    isLoadingData={isLoadingInfluencerLinksAnalytics}
                />
            </div>
        }
    }

    function closeLinkContentDialog() {
        setContentDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    return (
        <>
            <div className={isMobile ? 'mobile-influencer-preview-page-container' : 'influencer-preview-page-container'}>
                {
                    isMobile && <div className='mobile-influencer-preview-page-fixed-section'>
                        <div className="mobile-influencer-preview-topbar-right-section-container">
                            {renderDateRangeSelect()}
                        </div>
                    </div>
                }

                <div className={isMobile ? 'mobile-influencer-preview-page-content' : 'influencer-preview-page-content'}>
                    <ContentDialogTopbar
                        title={data?.influencerUsername}
                        onBackButtonClick={() => history.goBack()}
                        rightElement={<>
                            <BiDotsHorizontalRounded className='link-view-more-options-button' onClick={isMobile ? openOptionsDrawer : openOptionsMenu} />
                        </>}
                    />
                    <div className={isMobile ? "mobile-influencer-preview-influencer-overview" : "influencer-preview-influencer-overview"}>
                        <ImageBox
                            image={influencerData?.image}
                            className={isMobile ? 'mobile-influencer-preview-influencer-image' : 'influencer-preview-influencer-image'}
                            showImageLoader={true}
                            isImageFitCover={true}
                            roundImage={true}
                        />
                        <div className={isMobile ? "mobile-influencer-preview-influencer-details" : "influencer-preview-influencer-details"}>
                            {!isMobile && renderInfluencerAnalytics()}
                            <div className="influencer-preview-influencer-data">
                                <div className="influencer-preview-influencer-email">{influencerData?.email}</div>
                                {influencerData?.utm && <div className="influencer-preview-influencer-utm-container">
                                    <div className="influencer-preview-influencer-utm-label">{t('PREVIEW_INFLUENCER_DATA_UTM_LABEL')}</div>
                                    <div className="influencer-preview-influencer-utm-value">{influencerData?.utm}</div>
                                </div>}
                                {influencerData?.promocode && <div className="influencer-preview-influencer-promocode-container">
                                    <div className="influencer-preview-influencer-promocode-label">{t('PREVIEW_INFLUENCER_DATA_PROMOCODE_LABEL')}</div>
                                    <div className="influencer-preview-influencer-promocode-value">{influencerData?.promocode}</div>
                                </div>}
                                {![null, undefined].includes(influencerData?.commissionRate) && <div className="influencer-preview-influencer-commission-container">
                                    <div className="influencer-preview-influencer-commission-label">{t('PREVIEW_INFLUENCER_DATA_COMMISSION_LABEL')}</div>
                                    <div className="influencer-preview-influencer-commission-value">{influencerData?.commissionRate}%</div>
                                </div>}
                            </div>
                            {
                                (!isMobile && userDetails?.permissions?.isActiveInfluencer) && <div className='influencer-preview-buttons-section'>
                                    <div className='home-page-header-button home-page-header-create-link-button' onClick={createNewLink}>
                                        <BiLink className='home-page-header-button-image' />
                                        <div className="home-page-header-button-text">{t('HOME_PAGE_CREATE_LINK_BUTTON_TEXT')}</div>
                                    </div>
                                    <div className='home-page-header-button home-page-header-create-short-button' onClick={createNewShort}>
                                        <ShortsImage className='home-page-header-button-image' />
                                        <div className={isMobile ? "mobile-home-page-header-button-text" : "home-page-header-button-text"}>{t('HOME_PAGE_CREATE_SHORT_BUTTON_TEXT')}</div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {!isMobile && renderTabs()}
                    {isMobile && renderInfluencerAnalytics()}
                    {
                        isMobile && renderTabs()
                    }
                    <div ref={linksListRef} className='influencer-preview-links-shorts-list-container'>
                        {
                            (isLoadingInfluencerLinks && !isInsightsTabSelected()) && <div className="influencer-preview-links-shorts-list-loader-container">
                                <InfluencerLinksShortsLoader />
                            </div>
                        }
                        <div className={`${getItemsListClassName()} ${getNoLinksElementClassName()}`}>
                            {renderTabContent()}
                        </div>
                        {
                            (loadingMoreItems && (isLinksTabSelected() || isShortsTabSelected())) && <div className="influencer-preview-links-shorts-list-pagination-loader-container">
                                <Loader
                                    styles={{
                                        height: '25px',
                                        width: '25px',
                                        margin: '0'
                                    }}
                                />
                            </div>
                        }
                    </div>
                </div>
                <BrowserView>
                    <PaydinMenu
                        anchorElement={optionsMenuState.anchorElement}
                        isOpen={optionsMenuState.isOpen}
                        onClose={optionsMenuState.onClose}
                        direction={PAYDIN_MENU_OPENING_DIRECTIONS.RIGHT}
                        options={[
                            {
                                text: t('PREVIEW_INFLUENCER_MORE_OPTIONS_MENU_EDIT_OPTION_TEXT'),
                                onClick: editInfluencer
                            },
                            {
                                text: t('PREVIEW_INFLUENCER_MORE_OPTIONS_MENU_COPY_INFLUENCER_LOGIN_LINK_OPTION_TEXT'),
                                onClick: copyInfluencerLoginInformation
                            },
                            {
                                text: isLoadingInfluencerReport ? <Loader styles={{ height: '20px', width: '20px', marginInline: '50px' }} /> : t('PREVIEW_INFLUENCER_MORE_OPTIONS_MENU_DOWNLOAD_INFLUENCER_REPORT_OPTION_TEXT'),
                                onClick: isLoadingInfluencerReport ? () => {} : downloadInfluencerReport
                            },
                            {
                                text: t('PREVIEW_INFLUENCER_MORE_OPTIONS_MENU_RESET_INFLUENCER_PASSWORD_OPTION_TEXT'),
                                onClick: showResetInfluencerPasswordConfirmationDialog
                            },
                            {
                                text: t('PREVIEW_INFLUENCER_MORE_OPTIONS_MENU_DELETE_OPTION_TEXT'),
                                onClick: showDeleteInfluencerConfirmationDialog,
                                className: 'influencer-preview-more-options-menu-delete-option'
                            },
                            {
                                text: t('PREVIEW_INFLUENCER_MORE_OPTIONS_MENU_CANCEL_OPTION_TEXT'),
                                onClick: optionsMenuState.onClose
                            }
                        ]}
                    />
                </BrowserView>
            </div>
            <PaydinDialog
                isDialogOpen={dialogState.isOpen}
                handleDialogClose={dialogState.handleDialogClose}
                title={dialogState.title}
                message={dialogState.message}
                closeOnRightClick={false}
                rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                leftButtonText={dialogState.leftButtonText}
                rightButtonText={dialogState.rightButtonText}
                isLeftButtonWithLoader={false}
                isRightButtonWithLoader={true}
                onRightButtonClick={dialogState.rightButtonClickHandler}
            />
            <ContentDialog
                isDialogOpen={contentDialogState.isOpen}
                handleDialogClose={closeLinkContentDialog}
                data={contentDialogState.data}
                mode={contentDialogState.mode}
                contentType={contentDialogState.contentType}
                onSaveSuccess={contentDialogState.onSaveSuccess}
            />
            <AddLInkFlow
                trigger={addFlowTrigger}
                setTrigger={setAddFlowTrigger}
                data={{
                    influencerUsername: data?.influencerUsername
                }}
            />
        </>
    )
}