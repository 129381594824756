import React from 'react'
import './ProductItem.css'
import { useTranslation } from 'react-i18next'
import { BsCheck } from 'react-icons/bs'
import ImageBox from 'components/ImageBox/ImageBox'
import MultiLineText from 'components/MultiLineText/MultiLineText'
import { isMobile } from 'react-device-detect'
import { height } from '@mui/system'
import ColorfulTag from 'components/ColorfulTag/ColorfulTag'

/**
 * Represents a product item inside component.
 * @param {object} data - The data of this product
 * @param {function} onItemClick - A function to perfomr whenever this item is clicked
 */
export default function ProductItem({
    elementRef,
    data = {},
    imageWidth = '100px',
    imageHeight = '100px',
    showSellingScore = false,
    score = null,
}) {
    const { t } = useTranslation()

    function getVariantAvailabilityColor(ratio) {
        if (ratio >= 75) {
            return 'rgb(212 243 187)'
        } else if (ratio >= 50) {
            return 'rgb(243 224 187)'
        } else {
            return 'rgb(243 192 187)'
        }
    }

    return (
        <div 
            className={isMobile ? 'mobile-product-item-container' : 'product-item-container'} 
            ref={elementRef} 
            style={{
                width: isMobile ? imageWidth : `calc(2.5 * ${imageWidth}`, 
                minWidth: imageWidth, 
                height: isMobile ? `calc(${showSellingScore ? 1.8 : 1.6} * ${imageHeight}` : imageHeight
            }}
        >
            <div className='product-item-image-frame' style={{width: isMobile ? '100%' : `40%`, height: isMobile ? (showSellingScore ? '55%' : '62%') : `100%`}}>
                <ImageBox
                    className='product-item-image'
                    image={data?.imageUrl}
                    isImageFitCover={true}
                    showGreyImageBackground={true}
                    styles={{width: imageWidth, height: imageHeight}}
                    showImageBorderRadius={true}
                />
            </div>
            <div className='product-item-details' style={{width: isMobile ? '100%' : `60%`, height: isMobile ? (showSellingScore ? '45%' : '38%') : `100%`}}>
                <MultiLineText
                    text={data?.title}
                    className='product-item-title'
                    lines={2}
                    style={{height: '2lh'}}
                    treatAsHtml={true}
                />
                {score !== null && showSellingScore && <MultiLineText
                            text={t(score === 1 ? "PRODUCT_ITEM_BOUGHT_ONE_TIME_TEXT" : "PRODUCT_ITEM_BOUGHT_MULTIPLE_TIMES_TEXT",{times: score})}
                            className='product-item-selling-score-title'
                            lines={1}
                            style={{fontSize: isMobile ? '12px' : '14px'}}
                        />}
                <div className="product-item-tag-container">
                    <ColorfulTag
                        text={t("PRODUCT_ITEM_AVAILABILITY_TAG_TEXT", {score: Math.round(data?.variantAvailabilityRatio * 100)})}
                        backgroundColor={getVariantAvailabilityColor(Math.round(data?.variantAvailabilityRatio * 100))}
                        isSmall={true}
                        textSize={isMobile ? '10px' : '13px'}
                        showBullet={!isMobile}
                    />
                </div>
            </div>
        </div>
    )
}