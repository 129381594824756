import React, { useEffect, useState } from 'react'
import './InfluencersPage.css'
import { ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS, ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES, CONTENT_TYPES, NOT_PRO_MAX_INFLUENCER_NUMBER, PRICING_PLAN_DATA, VIEW_MODES } from 'constants/general.constants'
import { t } from 'i18next'
import InfluencerItem from 'components/InfluencerItem/InfluencerItem'
import { isMobile } from 'react-device-detect'
import NoItemsFound from 'components/NoItemsFound/NoItemsFound'
import { useHistoryContext } from 'contexts/History'
import { HOME_PAGE_URL, PRICING_PLANS_PAGE_URL } from 'constants/routes.constants'
import NoInfluencersFoundImage from 'embeddedImages/NoInfluencersFoundImage'
import { GetInfluencers, GetInfluencersAnalytics } from 'api/influencers/influencers'
import DashboardLoader from 'components/DashboardLoader/DashboardLoader'
import { useGeneralDataContext, useUserDetailsContext } from 'contexts/User'
import { extractBasicInfluencerAnalytics, extractInfluencerData } from 'utils/dataUtils'
import { getAnalyticsDateString } from 'utils/dateUtils'
import ContentDialog from 'dialogs/ContentDialog/ContentDialog'
import TimeSelect from 'components/TimeSelect/TimeSelect'
import AnalyticsStrip from 'components/AnalyticsStrip/AnalyticsStrip'
import { getCurrencySymbol } from 'services/currency'

/**
 * Represents the main influencers screen.
 */
export default function InfluencersPage() {
    const history = useHistoryContext()
    const {
        userDetails,
        maxInfleuncersAllowed,
        isFreeUserAndTrialEnded,
        hasShopifyStore,
        setRecentInfluencers,
    } = useUserDetailsContext()
    const {
        finishedUpdatingObject,
        finishedCreatingObject,
        objectToSave,
        openFreeTrialDialog,
    } = useGeneralDataContext()

    const defaultSelectedInterval = ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS_NAMES.THIS_MONTH

    const [influencersList, setInfluencersList] = useState([])
    const [influencersAnalytics, setInfluencersAnalytics] = useState([])
    const [isLoadingInfluencers, setIsLoadingInfluencers] = useState(true)
    const [isLoadingInfluencersAnalytics, setIsLoadingInfluencersAnalytics] = useState(false)
    const [shouldCheckForInfluncers, setShouldCheckForInfluncers] = useState(false)
    const [isInfluencerContentDialogOpen, setIsInfluencerContentDialogOpen] = useState(false)
    const [overviewTimeIntervalName, setOverviewTimeIntervalName] = useState(defaultSelectedInterval)
    const [datePickerState, setDatePickerState] = useState({
        startDate: ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS.find(timeInterval => timeInterval?.name === defaultSelectedInterval).timeRange[0],
        endDate: ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS.find(timeInterval => timeInterval?.name === defaultSelectedInterval).timeRange[1]
    })

    useEffect(() => {
        if (hasShopifyStore()) {
                fetchInflueners()
                setIsLoadingInfluencersAnalytics(false)
                setShouldCheckForInfluncers(true)
        } else {
            history.push(HOME_PAGE_URL)
        }
    }, [])

    useEffect(() => {
        setIsLoadingInfluencersAnalytics(true)
        GetInfluencersAnalytics(
            getAnalyticsDateString(datePickerState.startDate),
            getAnalyticsDateString(datePickerState.endDate)
        )
            .then(response => {
                setInfluencersAnalytics(response?.map(influencer => extractBasicInfluencerAnalytics(influencer)))
            })
            .catch(console.log)
            .finally(() => {
                setIsLoadingInfluencersAnalytics(false)
            })
    }, [datePickerState])

    useEffect(() => {
        // Check if of type influencer
        if (objectToSave && objectToSave?.username) {
            if (finishedUpdatingObject) {
                updateInfluencer(objectToSave)
            } else if (finishedCreatingObject) {
                addNewInfluencer(objectToSave)
            }
        }
    }, [finishedUpdatingObject, finishedCreatingObject])

    function onDateRangeSelected(startDate, endDate) {
        setDatePickerState({
            startDate,
            endDate
        })
    }

    function fetchInflueners() {
        setIsLoadingInfluencers(true)

        GetInfluencers()
            .then(response => {
                setInfluencersList(response?.filter(influencer => influencer?.is_active)?.map(influencer => extractInfluencerData(influencer)))
            })
            .catch(console.log)
            .finally(() => {
                setShouldCheckForInfluncers(true)
                setIsLoadingInfluencers(false)
            })
    }

    function closeInfluencerContentDialog() {
        setIsInfluencerContentDialogOpen(false)
    }

    function deleteInfluencer(influencerUsername) {
        setInfluencersList(prev => prev.filter(influencer => influencer?.username !== influencerUsername))
        setRecentInfluencers([])
        setIsLoadingInfluencers(false)
    }

    function updateInfluencer(updatedInfluencer) {
        setInfluencersList(prev => prev.map(influencer => influencer?.username === updatedInfluencer?.username ? updatedInfluencer : influencer))
    }

    function addNewInfluencer(newInfluencer) {
        setInfluencersList(prev => [newInfluencer, ...prev])
    }

    function hasNoInfluencers() {
        return influencersList?.length === 0
    }

    function addInfluencer() {
        const maxInfleuncers = maxInfleuncersAllowed()
        if (influencersList?.length >= maxInfleuncers) {
            if (maxInfleuncers === 3) {
                openFreeTrialDialog(true, t('TOO_MANY_INFLUENCER_3_DIALOG_TITLE'), t('TOO_MANY_INFLUENCER_3_DIALOG_SUBTITLE'))
            } else {
                openFreeTrialDialog(true, t('TOO_MANY_INFLUENCER_15_DIALOG_TITLE'), t('TOO_MANY_INFLUENCER_15_DIALOG_SUBTITLE'))
            }
        } else {
            setIsInfluencerContentDialogOpen(true)
        }
    }

    function upgradePlan() {
        history.push(PRICING_PLANS_PAGE_URL)
    }

    return (
        <div className='influencers-page-container'>
            {
                !isMobile && <div className={"influencers-page-topbar-container"}>
                    <div className={"influencers-page-topbar-titles-container"}>
                        <div className="influencers-page-topbar-page-title">
                            <div className={"influencers-page-topbar-title"}>{t('INFLUENCERS_PAGE_TITLE')}</div>
                            <TimeSelect
                                selectedStartDate={datePickerState.startDate}
                                selectedEndDate={datePickerState.endDate}
                                timeIntervals={ANALYTICS_PAGE_RETROACTIVE_DATA_REPORT_TIME_INTERVALS}
                                setStartEndDates={onDateRangeSelected}
                                selectedTimeInterval={overviewTimeIntervalName}
                                onTimeIntervalSelect={setOverviewTimeIntervalName}
                                displayShortDates={true}
                            />
                        </div>
                        <div className={"influencers-page-topbar-subtitle"}>{t('INFLUENCERS_PAGE_TOPBAR_SUBTITLE_TEXT')}</div>
                    </div>
                    {
                        (!isFreeUserAndTrialEnded() && !hasNoInfluencers()) && <div className="influencers-page-topbar-add-influencer-button" onClick={addInfluencer}>{t('INFLUENCERS_PAGE_TOPBAR_ADD_INFLUENCER_BUTTON_TEXT')}</div>
                    }
                </div>
            }
            <div className={`${isMobile ? "mobile-influencers-page-content" : "influencers-page-content"} ${hasNoInfluencers() ? 'no-influencers-content' : ''}`}>
                <div className="separator-line"></div>
                <AnalyticsStrip
                    analytics={{
                        sales: influencersAnalytics?.reduce((totalSalesSum, influencer) => (influencer?.totalSales ?? 0) + totalSalesSum, 0),
                        revenue: influencersAnalytics?.reduce((totalRevenueSum, influencer) => (influencer?.totalRevenue ?? 0) + totalRevenueSum, 0),
                    }}
                    currency={getCurrencySymbol(userDetails.businessCurrency)}
                    isLoadingAnalytics={isLoadingInfluencersAnalytics}
                    showClicks={false}
                />
                {isLoadingInfluencers && <DashboardLoader />}
                {
                    (!isLoadingInfluencers && shouldCheckForInfluncers && hasNoInfluencers()) ? <div className='influenceres-page-no-influencers-container'>
                        <NoItemsFound
                            isEmbeddedIcon={true}
                            image={<NoInfluencersFoundImage className='no-items-found-container-image' />}
                            title={t('INFLUENCERS_ITEM_NO_INFLUENCERS_FOUND_TITLE')}
                            subtitle={isFreeUserAndTrialEnded() ? t('INFLUENCERS_ITEM_NO_INFLUENCERS_FOUND_FREE_USER_SUBTITLE') : t('INFLUENCERS_ITEM_NO_INFLUENCERS_FOUND_SUBTITLE')}
                            buttonText={isFreeUserAndTrialEnded() ? t('INFLUENCERS_PAGE_TOPBAR_ADD_INFLUENCER_BUTTON_FREE_SUBSCRIPTION_TEXT') : t('INFLUENCERS_ITEM_NO_INFLUENCERS_FOUND_ADD_INFLUENCER_BUTTON_TEXT')}
                            buttonStyles={{
                                backgroundColor: isFreeUserAndTrialEnded() ? 'black' : 'white',
                                color: isFreeUserAndTrialEnded() ? 'white' : 'black'
                            }}
                            addItemButtonClickHandler={isFreeUserAndTrialEnded() ? upgradePlan : addInfluencer}
                        />
                    </div> : <div className={isMobile ? "mobile-influencers-page-content-influencers-list" : "influencers-page-content-influencers-list"}>
                        {
                            influencersList?.map(influencer => <InfluencerItem
                                key={influencer?.username}
                                image={influencer?.image}
                                username={influencer?.username}
                                isLoadingAnalytics={isLoadingInfluencersAnalytics}
                                clicks={influencersAnalytics?.find(influencerAnalytics => influencerAnalytics?.username === influencer?.username)?.totalClicks}
                                sales={influencersAnalytics?.find(influencerAnalytics => influencerAnalytics?.username === influencer?.username)?.totalSales}
                                onDelete={deleteInfluencer}
                                onUpdate={updateInfluencer}
                            />)
                        }
                    </div>
                }
            </div>
            <ContentDialog
                isDialogOpen={isInfluencerContentDialogOpen}
                handleDialogClose={closeInfluencerContentDialog}
                mode={VIEW_MODES.CREATE}
                contentType={CONTENT_TYPES.INFLUENCER}
                onCreateSuccess={addNewInfluencer}
            />
        </div>
    )
}