import { logger } from '../services/CloudLogger'
import { REQUEST_TIMEOUT_MILLISECONDS } from '../constants/general.constants'
import { REQUEST_HEADER_AUTH_KEY } from '../constants/api.constants'
import { AUTH_TOKEN_KEY_LOCAL_STORAGE } from '../constants/storage.constants'
import { LocalSharedPreferences } from '../services/SharedPreferences';


/**
 * This function is called on everey request response.
 * Returns the reponse data.
 * In Case of an error, throws an error with the error status.
 */
async function parseResponse(res) {
    let data = {};
    // If a body response exists, parse anx extract the possible properties
    if (res.ok) {
        data = res.status !== 204 ? await res.json() : {};
    } else {
        logger.log("Request Error: ", res.status);
        if (res.status === "401") {
            //window.location.reload(false);
            // TODO: handle it
        }
        throw new Error(res.status);
    }
    return data;
}

function fetchWithTimeout(url, options, timeout) {
    return Promise.race([
        fetch(url, options),
        new Promise((_, reject) =>
            setTimeout(() => reject("REQUEST_TIMEOUT_ERROR_MESSAGE"), timeout)
        )
    ]);
}
/**
 * This function is a wrpper function for the a simple fetch request.
 * In case of a successful request, returns the response.json() promise.
 * params:
 *      url - The full http server url for which the request will be sent.
 *      options - A dectionary defining the request:
 *          headers: The custom headers.
 *          query: custom queries.
 *          method: 'GET', 'POST, etc.
 *          body: The custom body.
 *          any extra options will be added as is to the request.
 */
 export function simple_request(url, options = {}, timeout = REQUEST_TIMEOUT_MILLISECONDS, isFile=false, shouldParseResponse=true) {
    const {
        headers,
        query = null,
        method = 'GET',
        body,
        ...extraOpts
    } = options;

    // Compose the request configuration object
    const reqOptions =  method === 'GET' ? {
        method,
        headers,
        query,
        ...extraOpts,
    } : {
        method,
        headers,
        body,
        query,
        ...extraOpts,
    };

    // If a body object is passed, automatically stringify it.
    if (reqOptions.body && !isFile) {
        reqOptions.body = JSON.stringify(reqOptions.body);
    }

    let queryString = '';
    if (query) {
        // Convert to encoded string and prepend with ?
        queryString = new URLSearchParams(query).toString();
        queryString = queryString && `?${queryString}`;
    }

    if (timeout) {
        return new Promise((resolve, reject) => {
            fetchWithTimeout(`${url}${queryString}`, reqOptions, timeout)
                .then((res) => resolve(shouldParseResponse ? parseResponse(res): res))
                .catch((error) => reject(error))
        })
    }
    
    return new Promise((resolve, reject) => {
        fetch(`${url}${queryString}`, reqOptions)
            .then((res) => resolve(shouldParseResponse ? parseResponse(res): res))
            .catch((error) => reject(error))
    })
}

/**
 * This function is a wrpper function for the a simple fetch request.
 * In case of a successful request, returns the response.json() promise.
 * params:
 *      url - The full http server url for which the request will be sent.
 *      options - A dectionary defining the request:
 *          headers: The custom headers.
 *          query: custom queries.
 *          method: 'GET', 'POST, etc.
 *          body: The custom body.
 *          any extra options will be added as is to the request.
 */
export function request(url, options = {}, timeout = REQUEST_TIMEOUT_MILLISECONDS, shouldParseResponse=true) {
    const {
        headers,
        query = null,
        method = 'GET',
        body,
        ...extraOpts
    } = options;

    // Compose the request configuration object
    const reqOptions = {
        method,
        headers: {
            'Content-Type': 'application/json',
            ...headers,
        },
        query,
        body: {
            // 'store_id': getStoreID(),
            ...body,
        },
        ...extraOpts,
    };

    return new Promise((resolve, reject) => {
        simple_request(url, reqOptions, timeout, false, shouldParseResponse).then(resolve).catch(reject);
    });
}

// For uploading files
export function request_post_file(url, options = {}, timeout = REQUEST_TIMEOUT_MILLISECONDS, shouldParseResponse=true) {
    const auth_token = LocalSharedPreferences.get_key(AUTH_TOKEN_KEY_LOCAL_STORAGE)
    if (auth_token) {
        options["headers"] = {
            ...options["headers"],
            [REQUEST_HEADER_AUTH_KEY]: auth_token,
        }
    }
    
    const {
        headers,
        query = null,
        method = 'GET',
        formData,
        ...extraOpts
    } = options;

    // formData.append('store_id', getStoreID());

    const reqOptions = {
        method,
        query,
        headers,
        body: formData,
        ...extraOpts,
    };

    return new Promise((resolve, reject) => {
        simple_request(url, reqOptions, timeout, true, shouldParseResponse).then(resolve).catch(reject);
    });
}

/**
 * This function is a wrpper function for the a fetch request.
 * It uses the Cognity Auth as an authentication service to get the authentication tokens and add them to the request.
 * In case of a successful request, returns the response.json() promise.
 * params:
 *      url - The full http server url for which the request will be sent.
 *      options - A dectionary defining the request:
 *          headers: The custom headers.
 *          query: custom queries.
 *          method: 'GET', 'POST, etc.
 *          body: The custom body.
 *          any extra options will be added as is to the request.
 *      isFile - If sending a file or regular jsonify data.
 *      timeour - The maximum time to process and get an answer.
 */
export function request_auth(url, options = {}, isFile=false, timeout = REQUEST_TIMEOUT_MILLISECONDS, shouldParseResponse=true) {
    return new Promise((resolve, reject) => {

        const auth_token = LocalSharedPreferences.get_key(AUTH_TOKEN_KEY_LOCAL_STORAGE)
        if (auth_token) {
            options["headers"] = {
                ...options["headers"],
                [REQUEST_HEADER_AUTH_KEY]: auth_token,
            }
        }

        if (isFile) {
            request_post_file(url, options, timeout, shouldParseResponse).then(resolve).catch(reject);
        } else {
            request(url, options, timeout, shouldParseResponse).then(resolve).catch(reject);
        }
    });
}