import React, { useEffect, useState } from 'react'
import './AddProductsDialogProduct.css'
import { ADD_PRODUCTS_DIALOG_PRODUCT_STATUS, COLOR_SELECT_CIRCLE_DIMENSIONS, IMAGE_LOADING_IMAGE_SRV_TEXT, OPTION_TYPES } from 'constants/general.constants'
import { useTranslation } from 'react-i18next'
import { BsCheck } from 'react-icons/bs'
import ImageBox from 'components/ImageBox/ImageBox'
import MultiLineText from 'components/MultiLineText/MultiLineText'
import { useGeneralDataContext } from 'contexts/User'
import ColorSelect from 'components/ColorSelect/ColorSelect'
import { cloneObject } from 'utils/dataUtils'
import { ProductOptionsImageAPI } from 'api/products/products'
import { isMobile } from 'react-device-detect'

/**
 * Represents a product list item inside {@link AddProductsDialog} component.
 * @param {object} data - The data of this product
 * @param {boolean} isItemSelected - Determins whether this item is selected or not
 * @param {function} onItemClick - A function to perfomr whenever this item is clicked
 */
export default function AddProductsDialogProduct({
    elementRef,
    data = {},
    isItemSelected = false,
    onItemClick = () => { },
    allowColorOptionSelect = false,
}) {
    const {
        productsOptionsMap,
    } = useGeneralDataContext()
    
    const { t } = useTranslation()
    
    const [colorOption, setColorOption] = useState({
        name: "",
        position: 0,
        values: [],
    })
    const [colorCounter, setColorCounter] = useState({})
    const [selectedColor, setSelectedColor] = useState("")
    const [currentImageUrl, setCurrentImageUrl] = useState(data?.imageUrl)


    useEffect(() => {
        if (allowColorOptionSelect && productsOptionsMap && !colorOption?.name && data?.options) {
            data?.options.map(option => {
                if (productsOptionsMap[option.name]?.type === OPTION_TYPES.COLOR && option?.values?.length > 1) {
                    if (!colorOption?.name || colorOption?.position > option?.position)
                        setColorOption(option)
                        let tempColorCounter = {}
                        option?.values?.forEach(value => tempColorCounter[value] = 1)
                        setColorCounter(tempColorCounter)
                        if (data?.selectedOptions[option.name]) {
                            setSelectedColor(data?.selectedOptions[option.name])
                        }
                }
            })
        } 
    }, [data, productsOptionsMap, allowColorOptionSelect])

    useEffect(() => {
        if (!isItemSelected && selectedColor) {
            setSelectedColor("")
        }
    }, [isItemSelected])

    function onColorSelected(color) {
        if (color) {
            // First choose and make current picture IMAGE_LOADING_IMAGE_SRV_TEXT to show loader
            let updatedItem = cloneObject(data)
            updatedItem.selectedOptions = {[colorOption?.name]: color}
            setCurrentImageUrl(IMAGE_LOADING_IMAGE_SRV_TEXT)
            onItemClick(updatedItem)
            setSelectedColor(color)
            ProductOptionsImageAPI(data?.id, {[colorOption?.name]: color})
                .then(image => {
                    // Second update picture
                    let updatedItem = cloneObject(data)
                    const newImagUrl = image?.src ?? data?.imageUrl
                    updatedItem.selectedOptions = {[colorOption?.name]: color}
                    updatedItem.imageUrl = newImagUrl
                    setCurrentImageUrl(newImagUrl)
                    onItemClick(updatedItem)
                })
                .catch(error => {
                    setCurrentImageUrl(data?.imageUrl)
                })
        } else {
            onItemClick()
            setSelectedColor(color)
        }
    }
    
    return (
        <div className='add-products-dialog-product-container' ref={elementRef}>
            <div className='add-products-dialog-product-image-frame' onClick={() => onItemClick()}>
                <ImageBox
                    className='add-products-dialog-product-image'
                    image={currentImageUrl}
                    isImageFitCover={true}
                    showGreyImageBackground={true}
                    showImageLoader={true}
                />
                {isItemSelected && <div className='selected-item-mask'>
                    <BsCheck className='add-products-dialog-product-selected-v-mark' />
                </div>}
                <div className="add-products-dialog-product-tags">
                    {data?.productStatus === ADD_PRODUCTS_DIALOG_PRODUCT_STATUS.DRAFT && <div className="add-products-dialog-product-draft-tag">{t('ADD_PRODUCTS_DIALOG_DRAFT_PRODUCT_TAG_TEXT')}</div>}
                    {data?.hasNoQuantity && <div className="add-products-dialog-product-out-of-stock-tag">{t('ADD_PRODUCTS_DIALOG_OUT_OF_STOCK_PRODUCT_TAG_TEXT')}</div>}
                </div>
            </div>
            <div className='add-products-dialog-product-details'>
                <MultiLineText
                    text={data?.title}
                    className='add-products-dialog-product-title'
                    lines={2}
                    onClick={() => onItemClick()}
                    treatAsHtml={true}
                />
                {data?.handle && <MultiLineText
                    text={data?.handle}
                    className='add-products-dialog-product-handle'
                    lines={1}
                    onClick={() => onItemClick()}
                />}
                {colorOption?.name && <ColorSelect
                    optionName={colorOption?.name}
                    selectedColor={selectedColor}
                    setSelectedColor={onColorSelected}
                    colorCounter={colorCounter}
                    colorCircleDimensionsPx={COLOR_SELECT_CIRCLE_DIMENSIONS}
                    onMoreColorsButtonClick={() => {}}
                    colorSelectDisplayedColorsCount={isMobile ? 3 : 4}
                />}
            </div>
        </div>
    )
}