import React, { useEffect, useRef, useState } from 'react'
import './ShortItem.css'
import ImageBox from 'components/ImageBox/ImageBox'
import { useTranslation } from 'react-i18next'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { FaChartSimple } from 'react-icons/fa6'
import { useGeneralDataContext, useUserDetailsContext } from 'contexts/User'
import PaydinMenu from 'components/PaydinMenu/PaydinMenu'
import {
    CONTENT_TYPES,
    LINKS_PAGE_MOBILE_OPTIONS_DRAWER_CLOSING_DELAY,
    NO_IMAGE_GLOBE_PLACEHOLDER_URL,
    NUMBER_TYPES,
    PAYDIN_MENU_OPENING_DIRECTIONS,
    SHORT_URL_SELECTION_TYPE,
    VIEW_MODES
} from 'constants/general.constants'
import { formatNumber } from 'utils/utils'
import { useHistoryContext } from 'contexts/History'
import PaydinDialog, { PAYDIN_DIALOG_BUTTON_TYPES } from 'dialogs/PaydinDialog/PaydinDialog'
import { BrowserView, isMobile } from 'react-device-detect'
import { environment } from 'conf'
import { DeleteLinkAPI } from 'api/links/links'
import { copyToClipboard } from 'services/SystemService'
import { MdOpenInNew, MdOutlineContentCopy, MdOutlineEdit } from 'react-icons/md'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { BsCalendar, BsCartCheck } from 'react-icons/bs'
import { getCurrencySymbol } from 'services/currency'
import { LiaCoinsSolid } from 'react-icons/lia'
import LinkUrlTypeTag from 'components/LinkUrlTypeTag/LinkUrlTypeTag'
import ContentDialog from 'dialogs/ContentDialog/ContentDialog'
import DrawerStyledButtonList from 'components/DrawerStyledButtonList/DrawerStyledButtonList'

/**
 * Represents a short item.
 * @param {string} shortId - The id of this short item 
 * @param {Image/string} image - The image of this short item 
 * @param {Image/string} influencerImage - The image of this short's influencer 
 * @param {number} createdAt - The epoch time number representing the creation date of this short
 * @param {number} views - The number of views for this short
 * @param {number} revenue - The revenue of this short
 * @param {string} currency - The currency of this short
 * @param {string} influencerUsername - The currency of this short
 * @param {function} onDeleteLink - A callback function to invoke after the short was deleted
 */
export default function ShortItem({
    shortId,
    image,
    defaultImage,
    influencerImage,
    createdAt,
    views,
    revenue,
    checkouts,
    currency,
    isLinkExpired,
    title,
    url,
    urlType,
    influencerUsername,
    onDeleteLink = () => { },
    onUpdateLink = () => { },
    allowOrderDetails = true,
    elementRef = null
}) {
    const { t } = useTranslation()
    const { userDetails } = useUserDetailsContext()
    const history = useHistoryContext()
    const {
        showApprovalMessage,
        openDrawer,
        closeDrawer,
    } = useGeneralDataContext()

    const {
        getFrontendUrl,
    } = useUserDetailsContext()

    const [isShortDeletionDialogOpen, setIsShortDeletionDialogOpen] = useState(false)
    const [optionsMenuState, setOptionsMenuState] = useState({
        isOpen: false,
        anchorElement: null,
        onClose: () => { }
    })
    const [linkContentDialogState, setLinkContentDialogState] = useState({
        isOpen: false,
        mode: VIEW_MODES.PREVIEW
    })

    const linkItemTitlesContainerRef = useRef(null)
    const linkItemTitleRef = useRef(null)

    useEffect(() => {
        linkItemTitleRef.current.style.maxWidth = `${linkItemTitlesContainerRef?.current?.clientWidth}px`
    }, [])

    function getFormattedDate(epochTime) {
        const date = new Date(epochTime)
        return date.toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })
    }

    function openOptionsMenu(e) {
        setOptionsMenuState({
            isOpen: true,
            anchorElement: e.currentTarget,
            onClose: () => setOptionsMenuState(prev => ({
                ...prev,
                isOpen: false
            }))
        })
    }

    function editShort() {
        if (isMobile) {
            history.goBack()
        } else {
            optionsMenuState.onClose()
        }

        setTimeout(() => {
            setLinkContentDialogState({
                isOpen: true,
                mode: VIEW_MODES.EDIT
            })
        }, 200)
    }

    function previewShort(closeDialog = false) {
        if (isMobile) {
            if (closeDialog) {
                history.goBack()
            }
        } else {
            if (closeDialog) {
                optionsMenuState.onClose()
            }
        }

        if (closeDialog) {
            setTimeout(() => {
                setLinkContentDialogState({
                    isOpen: true,
                    mode: VIEW_MODES.PREVIEW
                })
            }, 200)
        } else {
            setLinkContentDialogState({
                isOpen: true,
                mode: VIEW_MODES.PREVIEW
            })
        }
    }

    async function copyShort(closeDialog = false) {
        if (isMobile && closeDialog) {
            closeDrawer()
        }

        const url = `${getFrontendUrl()}${userDetails?.username}/${shortId}`
        await copyToClipboard(url)
        showApprovalMessage(t('SHORT_COPIED_TO_CLIPBOARD'))
    }

    function openDeleteLinkConfirmationDialog() {
        if (isMobile) {
            closeDrawer()
            setTimeout(() => {
                setIsShortDeletionDialogOpen(true)
            }, LINKS_PAGE_MOBILE_OPTIONS_DRAWER_CLOSING_DELAY)
        } else {
            optionsMenuState.onClose()
            setIsShortDeletionDialogOpen(true)
            history.addBackHandler(() => setIsShortDeletionDialogOpen(false))
        }
    }

    function deleteLink() {
        DeleteLinkAPI(shortId)
            .then(response => {
                closeDialog()
                onDeleteLink(shortId)
            })
            .catch(error => {
                console.log(error)
            })
    }

    function closeDialog() {
        setIsShortDeletionDialogOpen(false)
    }

    function openOptionsDrawer() {
        openDrawer(true, <>
            <div className="mobile-short-item-more-options-menu-container">
                <div className="mobile-short-item-more-options-menu-item-line">
                    <div className="mobile-short-item-more-options-menu-single-item" onClick={() => copyShort(true)}>
                        <MdOutlineContentCopy className="mobile-short-item-more-options-menu-single-item-image" />
                        <div className="mobile-short-item-more-options-menu-single-item-text">{t('LINKS_PAGE_OPTIONS_MENU_ITEM_COPY_LINK')}</div>
                    </div>
                    <div className="mobile-short-item-more-options-menu-single-item" onClick={() => previewShort(true)}>
                        <MdOpenInNew className="mobile-short-item-more-options-menu-single-item-image" />
                        <div className="mobile-short-item-more-options-menu-single-item-text">{t('LINKS_PAGE_OPTIONS_MENU_ITEM_OPEN')}</div>
                    </div>
                </div>
                <DrawerStyledButtonList
                    buttons={[
                        ...((userDetails?.isAdmin || (!userDetails?.isAdmin && userDetails?.permissions?.isActiveInfluencer)) ? [{
                            text: t('LINKS_PAGE_OPTIONS_MENU_ITEM_EDIT'),
                            image: <MdOutlineEdit className="mobile-short-item-more-options-menu-section-item-image" />,
                            onClick: editShort
                        }] : []),
                        ...((userDetails?.isAdmin || (!userDetails?.isAdmin && userDetails?.permissions?.isActiveInfluencer)) ? [{
                            text: t('LINKS_PAGE_OPTIONS_MENU_ITEM_DELETE'),
                            image: <RiDeleteBin6Line className="mobile-short-item-more-options-menu-section-item-image" />,
                            onClick: openDeleteLinkConfirmationDialog,
                            color: 'red'
                        }] : [])
                    ]}
                />
            </div>
        </>)
    }

    function getTitle() {
        return !title || title?.length === 0 ? url : title
    }

    function hasSalesPermission() {
        return userDetails?.isAdmin || userDetails?.permissions?.salesEnabled
    }

    function hasRevenuePermission() {
        return userDetails?.isAdmin || userDetails?.permissions?.revenueEnabled
    }

    function closeLinkContentDialog() {
        setLinkContentDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    function getDisplayImage(image, defaultImage) {
        if (image) {
            return image
        }
        if (defaultImage) {
            return defaultImage
        }
        return NO_IMAGE_GLOBE_PLACEHOLDER_URL
    }

    return (
        <div ref={elementRef} className={isMobile ? 'mobile-short-item-container' : 'short-item-container'}>
            <div className="short-item-content">
                <div className={isMobile ? 'mobile-short-item-details-container' : 'short-item-details-container'}>
                    <ImageBox
                        image={getDisplayImage(image, defaultImage)}
                        alt='short image'
                        className='short-item-image'
                        isImageFitCover={true}
                        onImageClickCallback={() => previewShort(false)}
                    />
                    <div ref={linkItemTitlesContainerRef} className="short-item-details">
                        <div className="short-item-buttons">
                            <div className="short-item-copy-to-clipboard-button" onClick={() => copyShort(false)}>
                                <MdOutlineContentCopy className='short-item-copy-to-clipboard-button-image' />
                                <div className="short-item-copy-to-clipboard-button-text">{t('SHORTS_ITEM_COPY_TO_CLIPBOARD_BUTTON_TEXT')}</div>
                            </div>
                            <BiDotsVerticalRounded className={isMobile ? 'mobile-short-item-statistics-more-options-button' : 'short-item-statistics-more-options-button'} onClick={isMobile ? openOptionsDrawer : openOptionsMenu} />
                        </div>
                        <div className="short-item-details-lower-row">
                            <div className="short-item-created-at-expired-container">
                                <div className="short-item-titles-container">
                                    <div ref={linkItemTitleRef} className={isMobile ? "mobile-short-item-title" : "short-item-title"} onClick={() => previewShort(false)}>{getTitle()}</div>
                                </div>
                            </div>
                            <div className='show-item-statistics' onClick={() => previewShort(false)}>
                                <div className="short-item-created-at-container">
                                    <div className="short-item-statistics-created-at-container short-item-detail-container">
                                        <BsCalendar className='short-item-statistics-created-at-image short-item-statistics-image' />
                                        <div className={`short-item-statistics-created-at-value ${isMobile ? 'mobile-short-item-statistics-value' : 'short-item-statistics-value'}`}>{getFormattedDate(createdAt)}</div>
                                    </div>
                                    {
                                        urlType !== SHORT_URL_SELECTION_TYPE.CUSTOM && <LinkUrlTypeTag
                                            type={urlType}
                                            isSmall={true}
                                        />
                                    }
                                </div>
                                <div className="short-item-statistics-container">
                                    <div className="short-item-statistics-views-container short-item-detail-container">
                                        <FaChartSimple className='short-item-statistics-clicks-image short-item-statistics-image' />
                                        <div className={`short-item-statistics-views-value ${isMobile ? 'mobile-short-item-statistics-value' : 'short-item-statistics-value'}`}>{formatNumber(Math.max(views ?? 0, checkouts), NUMBER_TYPES.SHORT)}</div>
                                        <div className={`short-item-statistics-views-value-suffix ${isMobile ? 'mobile-short-item-statistics-value-suffix' : 'short-item-statistics-value-suffix'}`}>{t('SHORTS_ITEM_STATISTICS_CLICKS_VALUE_SUFFIX')}</div>
                                    </div>
                                    {
                                        hasSalesPermission() && <div className="short-item-statistics-checkouts-container short-item-detail-container">
                                            <BsCartCheck className='short-item-statistics-checkouts-image short-item-statistics-image' />
                                            <div className={`short-item-statistics-checkouts-value ${isMobile ? 'mobile-short-item-statistics-value' : 'short-item-statistics-value'}`}>{formatNumber(checkouts, NUMBER_TYPES.SHORT)}</div>
                                            <div className={`short-item-statistics-checkouts-value-suffix ${isMobile ? 'mobile-short-item-statistics-value-suffix' : 'short-item-statistics-value-suffix'}`}>{t('SHORTS_ITEM_STATISTICS_CHECKOUTS_VALUE_SUFFIX')}</div>
                                        </div>
                                    }
                                    {
                                        hasRevenuePermission() && <div className="short-item-statistics-revenue-container short-item-detail-container">
                                            <LiaCoinsSolid className='short-item-statistics-revenue-image short-item-statistics-image' />
                                            <div className={`short-item-statistics-revenue-value ${isMobile ? 'mobile-short-item-statistics-value' : 'short-item-statistics-value'}`}>{getCurrencySymbol(currency)}{formatNumber(revenue, NUMBER_TYPES.SHORT, false, isMobile ? 1000 : 10_000)}</div>
                                        </div>
                                    }
                                </div>
                            </div>
                            {influencerImage && <ImageBox
                                image={influencerImage}
                                className="short-item-attached-influencer-image"
                                roundImage={true}
                            />}
                        </div>
                    </div>
                </div>
            </div>
            <BrowserView>
                <PaydinMenu
                    anchorElement={optionsMenuState.anchorElement}
                    isOpen={optionsMenuState.isOpen}
                    onClose={optionsMenuState.onClose}
                    direction={PAYDIN_MENU_OPENING_DIRECTIONS.LEFT}
                    options={[
                        {
                            text: t('LINKS_PAGE_OPTIONS_MENU_ITEM_OPEN'),
                            onClick: previewShort
                        },
                        ...((userDetails?.isAdmin || (!userDetails?.isAdmin && userDetails?.permissions?.isActiveInfluencer)) ? [{
                            text: t('LINKS_PAGE_OPTIONS_MENU_ITEM_EDIT'),
                            onClick: editShort
                        }] : []),
                        ...((userDetails?.isAdmin || (!userDetails?.isAdmin && userDetails?.permissions?.isActiveInfluencer)) ? [{
                            className: 'short-item-options-menu-delete-option',
                            text: t('LINKS_PAGE_OPTIONS_MENU_ITEM_DELETE'),
                            onClick: openDeleteLinkConfirmationDialog
                        }] : [])
                    ]}
                />
            </BrowserView>
            <PaydinDialog
                title={t('LINKS_PAGE_LINK_DELETION_CONFIRMATION_DIALOG_TITLE')}
                message={t('LINKS_PAGE_LINK_DELETION_CONFIRMATION_DIALOG_MESSAGE')}
                isDialogOpen={isShortDeletionDialogOpen}
                handleDialogClose={closeDialog}
                leftButtonText={t('LINKS_PAGE_LINK_DELETION_CONFIRMATION_DIALOG_LEFT_BUTTON_TEXT')}
                rightButtonText={t('LINKS_PAGE_LINK_DELETION_CONFIRMATION_DIALOG_RIGHT_BUTTON_TEXT')}
                rightButtonType={PAYDIN_DIALOG_BUTTON_TYPES.SECONDARY}
                onRightButtonClick={deleteLink}
                closeOnRightClick={false}
                isLeftButtonWithLoader={false}
            />
            <ContentDialog
                isDialogOpen={linkContentDialogState.isOpen}
                handleDialogClose={closeLinkContentDialog}
                data={{
                    linkId: shortId,
                    allowOrderDetails,
                }}
                mode={linkContentDialogState.mode}
                contentType={CONTENT_TYPES.SHORT}
                onDelete={onDeleteLink}
                onSaveSuccess={onUpdateLink}
            />
        </div>
    )
}